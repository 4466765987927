import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { graphql, Link } from 'gatsby'
import comicGreenBG from "../images/comic-green-background-small.svg"
import comicBlueBG from "../images/comic-blue-background-small.svg"
import { ImHome } from "react-icons/im"
import Social from "../components/Social"
import SubscribeForm from "../components/SubscribeForm"
import { IoRocketOutline } from "react-icons/io5"
import GoogleMap from "../components/GoogleMap"

const LocationList = ({data, location, pageContext}) => {
    const locationdata = data.locationpage.childMarkdownRemark
    const frontmatter = locationdata.frontmatter
    const locsfull = data.locsdata
    const { currentPage, numLocs } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numLocs
    const prevPage = currentPage -1 === 1 ? "/locations/" : ("/locations/"+(currentPage -1).toString())
    const nextPage = ("/locations/"+(currentPage +1).toString())
 
    return (
        <>
            <Layout location={location}>
                <SEO title={frontmatter.seotitle} description={frontmatter.seometadesc} />
                <PageHero heading={frontmatter.description} />
                <section className="container mx-auto text-center max-w-7xl pt-4">
                    <div className="px-8 pt-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                            <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                            </li>
                            <li className="inline-block">
                                <span className="px-2">{frontmatter.title}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row print-section">
                        <div className="lg:w-2/3">
                            <div className="p-6 cmscontent" dangerouslySetInnerHTML={{ __html: locationdata.html}} />
                            <GoogleMap zoom={frontmatter.mapzoom} maptitle={frontmatter.maptitle}/>
                        </div>
                        <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                            <div className="lg:sticky top-24">
                                <div className="text-xs md:text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                    <div className="inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                                </div>
                                <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                                <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                                <h3 className="hidden lg:inline-block pulse heading-box-text2 text-2xl pt-4 mx-8">
                                    <span>it's good to share!</span>
                                </h3>
                                <Social location={location} notitle={true} />
                                <div className="hidden lg:inline-block">
                                    <SubscribeForm formid="subscribe-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="comic">
                        {locsfull.edges.map((edge, index) => {
                            const { frontmatter, fields } = edge.node
                            const alternateBG = index %2 === 0
                            let comicBG
                            alternateBG? comicBG = comicBlueBG : comicBG = comicGreenBG
                            return (
                                <div key={edge.node.id} className="w-full">
                                    <Link to={fields.slug}>
                                        <div className="panel pulserev" style={{backgroundImage: "url(" + comicBG + ")", backgroundSize: "cover", overflow: "hidden",}}>
                                            <div className="flex-auto flex justify-between flex-col">
                                                <div className="flex text-left">
                                                    <div className="flex-1 speech text-base sm:text-xl 2xl:text-2xl pb-6">
                                                        <h2 className="text-xl sm:text-2xl 2xl:text-4xl py-3 font-mono">{frontmatter.title}</h2>
                                                        <p>{frontmatter.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row pt-8">
                        <div className="text-left pr-4">
                            {!isFirst && (
                                <Link className="pulse heading-box-text-blog1 text-2xl px-6" to={prevPage}><span>Previous Page</span></Link>
                            )}
                        </div>
                        <div className="text-right pl-4">
                            {!isLast && (
                                <Link className="pulse heading-box-text-blog2 text-2xl px-6" to={nextPage}><span>Next Page</span></Link>
                            )}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default LocationList

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    locsdata: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(locations)/.*[.]md$/"}}, 
        sort: {fields: [frontmatter___date], order: DESC}, 
        limit: $limit, 
        skip: $skip
      ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            description
            featureimagealt
            featureimage {
                childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
                extension
                publicURL
            }
          }
        }
      }
    }
    locationpage: file(relativePath: {eq: "page/location/index.md"}) {
        childMarkdownRemark {
          html
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            datemod(formatString: "DD MMMM, YYYY")
            description
            featureimagealt
            seometadesc
            seotitle
            title
            mapzoom
            maptitle
            featureimage {
              extension
              publicURL
            }
          }
        }
      }
  }
`